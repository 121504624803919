@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");

$font-quicksand: "Quicksand", sans-serif;

.font-quicksand- {
  &-normal {
    font-family: $font-quicksand;
    font-weight: 400;
  }
  &-medium {
    font-family: $font-quicksand;
    font-weight: 500;
  }
  &-semibold {
    font-family: $font-quicksand;
    font-weight: 600;
  }
  &-bold {
    font-family: $font-quicksand;
    font-weight: 700;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap");

$font-fredoka-one: "Fredoka One", cursive;

.font-fredoka-one {
  font-family: $font-fredoka-one;
}

