$azul: #22ccd2;
$laranja: #ff6900;
$amarelo: #d29f13;
$cinza-escuro: darkgrey;

$vermelho: #780000;
$dourado: #d69947;
$verde: #00a783;
$amarelo: #f9aa3b;
$vermelhoVivo: #de002c;
$quaseBranco: #f0f2f5;
$socioTextoEscuro: #58323b;
$cinza: #969cb2;
$preto: #333333;
$branco: #fff;
$cinzaClaroAzulado: #acb7c1;

$verdeGraficos: #00e396;
$azulGraficos: #008ffb;

