@import "../../styles/colors";

// html{
//   scroll-behavior: smooth;
// }

.titles {
  h3 {
    font-size: 32px;
  }
  span {
    font-size: 16px;
    color: #847770;
  }
}

.landform {
  // background-image: url(/images/landform.svg);
  background-repeat: no-repeat;
  background-position: top;
  height: 25px;
  width: 100%;
}

.servicos-home {
  background-color: #fff;
  padding: 115px 0;
  .card-servicos {
    background: #ffffff;
    border: 1px solid #ffedad;
    border-radius: 16px !important;
    outline: none;
    img {
      height: 160px;
      object-fit: cover;
      border-radius: 16px 16px 0 0 !important;
    }
    a {
      button {
        // width: 120px !important;
        margin-top: -20px;
        position: absolute;
      }
    }
    .card-body {
      span {
        font-size: 20px;
        color: #401f00;
      }
      p {
        font-size: 14px;
        color: #6c6c6c;
      }
    }
  }
}

.viewer-360-home {
  background: url("../../images/fachadArena.jpg") no-repeat center center;
  background-size: cover;
  background-attachment: fixed;
  width: 100%;
  height: 500px;
  position: relative;
  
  .landform {
    margin-top: -2px;
  }
  .viewer-360-content {
    span {
      font-size: 32px;
      color: #fff;
      font-size: 35px !important;
    }
    p {
      font-size: 20px;
      color: #efefef;
      width: 40%;
      

      @media (max-width: 490px) {
        width: 100%;
      }
    }
    a {
      text-decoration: none;
    }
    button {
      width: auto !important;
      padding: 0 30px;
      height: 71px;
    
      
      span {
        font-size: 18px !important;
      }
    }
  }
}
.viewer-360-home:before {
  content: " ";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(44, 204, 211, 0.75);
}

.apresentacao-home {
  background-color: #fff;
  width: 100%;
  height: 800px;

  .video-wrapper {
    max-width: 700px;
    width: 100%;
  }

  .apresentacao-video {
    height: 0;
    padding-bottom: 56.25%;
    width: 100%;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }

    img:nth-child(1) {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      border: 10px solid #efefef;
      border-radius: 16px;
      // position: relative;
    }
    img:nth-child(2) {
      position: absolute;
      top: 50%;
      left: 50%;
      cursor: pointer;
      transition: 1s;
      transform: translate(-50%, -50%);
    }
    img:nth-child(2):hover {
      // transform: scale(1.05);
    }
  }
}

.endereco-home {
  background: #fff;
  .landform {
    margin-top: 14px;
    position: absolute;
  }
  iframe {
    margin-bottom: -5px;
  }
  .endereco-box {
    width: 284px;
    height: 142px;
    background: #ffffff;
    border: 1px solid $azul;
    box-shadow: 0px 16px 24px rgba(52, 113, 116, 0.1);
    border-radius: 16px;
    margin-top: -55px;
    position: absolute;
    z-index: 1;
    span,
    p {
      color: #401f00;
    }
  }  
  .endereco-reserve {
    background-color: $azul;
    position: relative;
    width: 100%;
    height: 750px;
    padding-bottom: 100px;
    h3 {
      color: #fff;
    }
    a {
      text-decoration: none;
    }
    button {
      width: auto !important;
      padding: 0 30px;
      height: 71px;
      span {
        font-size: 18px !important;
      }
    }
    .endereco-wave {
      position: absolute;
      width: 100%;
      // height: 200px;
      bottom: 0;
      margin-bottom: -10px;
      left: 0;
    }
  }
}
