.form-custom {
    padding: 3rem;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 16px #a8bebe38;
    border-radius: 8px;
    opacity: 1;

    @media (max-width: 430px) {
        padding: 1rem;
    }
}
