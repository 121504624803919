@import "../../styles/colors";

.left-container {
    border-radius: 8px;
    background: #ffffff 0% 0% no-repeat padding-box;
}

.central-vertical {
    // height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.rigth-container {
    background: #ffffff;
    border-radius: 8px;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: auto;
    max-width: 100%;

    position: relative;
    padding: 30% 2em;
    box-sizing: border-box;

    $border: 5px;
    color: rgb(0, 0, 0);
    background: #ffffff;
    border: solid $border transparent;
}

.module-border-wrap {
    padding: 1rem;
    position: relative;
    background: linear-gradient(90deg, $laranja 45%, $amarelo 55%, $azul 100%) !important;
    padding: 3px;
    border-radius: 8px;    
    // width: 33.33333%;
    
    }

.main-container {
    height: 70vh;
    min-height: 80vh;
    margin: 10vh auto;
}
