@import url("../../fonts/stylesheet.css");
@import "../../styles/colors";

.input {
    &-container {
        position: relative;
        border-bottom: 1px solid $amarelo;
        height: 61px;
    }

    &-field {
        display: block;
        width: 100%;
        padding: 0.75rem;
        font-size: 1.5rem;
        outline: none;
        border: none;
        background-color: transparent;
        font-family: "Quicksand";
        &.on-focus + .input-label p {
            transform: translateY(-40px);
            
        }

        &:disabled {
            background: #ccc;
            cursor: not-allowed;
            height: 40px;
            bottom: 0;
            position: absolute;
        }
    }
    
    &-label {
        display: flex;
        position: absolute;
        top: 90%;
        // color: $purple-400;
        transform: translate(15px, -70%);
        pointer-events: none;
        font-size: 1.25rem;
        font-family: "Quicksand";
        color: #666666;
        p {
            transition: transform 150ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
        }

        @for $i from 1 to 9 {
            & p:nth-child(#{$i}) {
                transition-delay: #{$i * 90}ms;
            }
        }
    }
}

.react-date-picker__wrapper {
    border: 0 !important;
}

.react-date-picker__calendar {
    z-index: 5 !important;
}

.react-calendar__month-view__weekdays__weekday {
    // white-space: nowrap !important;
    // width: 50px !important;
    // overflow: hidden !important;
    text-overflow: clip !important;
    // border: 1px solid #000000 !important;
}

.react-calendar__navigation__prev-button {
    &:disabled {
        border-top-left-radius: 20px;
        color: $laranja;
        background-color: $laranja;
    }
    &:enabled{
        color: #fff;
        background-color: $laranja;
    }
}
.react-calendar__navigation__next-button {
    &:disabled {
        border-top-right-radius: 20px;
        color: $laranja;
        background-color: $laranja;
    }
    &:enabled{
        color: #fff;
        background-color: $laranja;
    }
}

.bulgy-radios {
    label {
        display: block;
        position: relative;
        padding-left: 2.3rem;
        cursor: pointer;
        &:hover input:not(:checked) ~ .radio {
            opacity: 0.8;
        }
    }
    .label {
        // display: flex;
        align-items: center;
        font-size: 1.4rem;
        font-family: Quicksand;

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        span {
            line-height: 1em;
        }
    }

    input {
        position: absolute;
        cursor: pointer;
        height: 0;
        width: 0;
        // left: -2000px;

        &:checked {
            ~ .radio {
                background-color: $laranja;
                transition: background 0.3s;
                &::after {
                    opacity: 1;
                }
            }
            ~ .label {
                // color: #a8bebe;
                color: black;
                span {
                    // animation: bulge 0.5s forwards;
                }

                //adjust this if label is going to be more than 20 chars
                @for $i from 1 to 20 {
                    span:nth-child(#{$i}) {
                        // animation-delay: $i * 0.025s;
                    }
                }
            }
        }
    }
}
.radio {
    position: absolute;
    top: 0.2rem;
    left: 0;
    height: 1.5rem;
    width: 1.5rem;
    background: #c9ded6;
    border-radius: 50%;
    &::after {
        content: "";
        position: absolute;
        opacity: 0;
        top: 0.4rem;
        left: 0.4rem;
        width: 0.7rem;
        height: 0.7rem;
        border-radius: 50%;
        background: #fff;
    }
}
@keyframes bulge {
    50% {
        transform: rotate(4deg);
        font-size: 1.5em;
        font-weight: bold;
    }
    100% {
        transform: rotate(0);
        font-size: 1em;
        font-weight: bold;
    }
}

.card-radios {
    border: 1px solid rgba(0, 0, 0, 0.125);
    margin-top: 10px;
    width: 227px;
    height: 71px;
    border-radius: 14px;
    // padding: 1rem;
    display: flex;
    flex-direction: row;
}

.select-border-inputRardio {
    border: 2px solid $laranja;
}

.radio-disabled {
    position: absolute;
    top: 1.2rem;
    left: 5px;
}

[type="file"] {
    height: 0;
    overflow: hidden;
    width: 0;
}

[type="file"] + label {
    background: #f15d22;
    border: none;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: "Quicksand";
    font-size: inherit;
    font-weight: 500;
    margin-bottom: 1rem;
    outline: none;
    padding: 0rem 12px;
    position: relative;
    transition: all 0.3s;
    vertical-align: middle;
    align-self: flex-end;
    height: auto;

    &:hover {
        background-color: darken(#f15d22, 10%);
    }

    &.btn-1 {
        background-color: #f79159;
        box-shadow: 0 6px darken(#f79159, 10%);
        transition: none;

        &:hover {
            box-shadow: 0 4px darken(#f79159, 10%);
            top: 2px;
        }
    }

    &.btn-2 {
        background-color: #99c793;
        border-radius: 50px;
        overflow: hidden;

        &::before {
            color: #fff;
            content: "\f382";
            font-family: "Font Awesome 5 Pro";
            font-size: 100%;
            height: 100%;
            right: 130%;
            line-height: 3.3;
            position: absolute;
            top: 0px;
            transition: all 0.3s;
        }

        &:hover {
            background-color: darken(#99c793, 30%);

            &::before {
                right: 75%;
            }
        }
    }

    &.btn-3 {
        background-color: #ee6d9e;
        border-radius: 0;
        overflow: hidden;

        span {
            display: inline-block;
            height: 100%;
            transition: all 0.3s;
            width: 100%;
        }

        &::before {
            color: #fff;
            content: "\f382";
            font-family: "Font Awesome 5 Pro";
            font-size: 130%;
            height: 100%;
            left: 0;
            line-height: 2.6;
            position: absolute;
            top: -180%;
            transition: all 0.3s;
            width: 100%;
        }

        &:hover {
            background-color: darken(#ee6d9e, 30%);

            span {
                transform: translateY(300%);
            }

            &::before {
                top: 0;
            }
        }
    }
}
