@import "../../styles/colors";

.header-home {
  background-color: #fff;
  video {
    height: 100%;
    width: 100% !important;
    -o-object-fit: cover;
    object-fit: cover;
    position: absolute;
  }
  // .video::after{
  //   left: 0;
  //   top: 0;
  //   width: 100%;
  //   height: 100%;
  //   background: #030303;
  //   position: relative;
  // }

  .wellcome {
    color: #fff;
    position: relative;
    
    h1 {
      font-size: 72px;
      @media (max-width: 991px) {
        font-size: 30px;
      }
    }
    span {
      font-size: calc(14px + (24 - 14) * ((100vw - 300px) / (1600 - 300)));
    }
    button {
      height: 60px !important;
      padding: 0 35px !important;
    }
  }
  .hed{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0;
    background: rgba(0, 0, 0, 0.5);
  }
}

