@import "../../styles/colors";

.eventos-link {
  text-decoration: none;
  color: #fff;
  font-size: 24px;
  font-weight: bold;
  font-family: "Quicksand";
}

.eventos--grande {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  word-break: break-all;
  border-radius: 16px;
}

.eventos-data-tipo {
  color: #fff;
  text-decoration: none;
  font-size: 12px;
  font-family: "Quicksand";
}

.eventos-data-tipo-separador {
  color: #fff;
  text-decoration: none;
  margin: 0px 8px;
  font-size: 12px;
}

.evento-data-tipo-separador {
  color: #000;
  text-decoration: none;
  margin: 0px 8px;
}

.eventos-pequenas-data-tipo-separador {
  color: #000;
  text-decoration: none;
  font-size: 10px;
  margin: 0px 8px;
}

.evento-data-tipo {
  color: #000;
  text-decoration: none;
}

.evento-data-tipo--bold {
  color: #000;
  text-decoration: none;
  font-weight: bold;
}

.imagem-evento--pequena {
  border-radius: 16px;
  object-fit: cover;
  width: 100%;
  @media (min-width: 576px) {
    max-height: 200px;
    max-width: 250px;
  }
}

.eventos-fonte--pequena-bold {
  font-weight: bold;
}
.line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.eventos-pequenas-data-tipo {
  color: $preto;
  font-size: 12px;
  font-family: "Quicksand";
}

.eventos-fonte--media {
  font-size: 16px;
  color: $laranja;
  font-family: "Quicksand";
}

.evento-fonte--media {
  font-size: 32px;
  color: $laranja;
  word-break: break-all;
}

.eventos-fonte--pequena {
  font-size: 14px;
  color: $preto;
  font-family: "Quicksand";
}
.eventos-fonte--pequena-gold {
  font-size: 14px;
  color: $laranja;
  font-weight: bold;
}

.font-12 {
  font-size: 12px;
  color: $preto;
}

// .evento-pequena-container{
//   @media (max-width: 575px) {
//     padding:0;
//   }
// }

.evento-pequena-texto {
  margin-left: 1.5rem;

  @media (max-width: 575px) {
    margin: 0;
    padding: 0;
  }
}

.evento-pequena-texto-home {
  margin-left: 1.5rem;
  @media (max-width: 575px) {
    margin: 20px 0;
    padding: 0;
  }
}

.eventos-mobile {
  word-break: break-all;
  @media (max-width: 575px) {
    flex-direction: column;
    width: 100%;
    img {
      max-width: 100%;
      max-height: auto;
    }
  }
}

.filter-news-container {
  background-color: #f0f0f0;
}

.filter-news {
  justify-content: space-evenly;
  list-style-type: none;
  padding: 0;
  margin: 0;
  color: $laranja;
  font-weight: bold;
  row-gap: 16px;
}

.filter-eventos {
  height: 40px;
  border-radius: 20px;
  background-color: $branco;
}

.form-pesquisa-news {
  display: flex;
  flex: 1;
}

// .nav-2{
//    padding: 2.5em;
// }

.coluna-evento {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.evento-texto {
  // padding: 0 4em;

  // @media (max-width: 575px) {
  //   padding: 0;
  // }
  margin-top: 40px;
  max-width: 752px;
  width: 100%;
}

.evento-imgs-texto {
  font-size: 16px;
  max-width: 752px;
  margin: 0 auto;
}

.evento-imgs-container {
  display: flex;
  flex-direction: row;
}

.evento-imgs {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  // a{
  //   &+a{
  //     margin-left: 24px;
  //   }
  // }
}

.button-centered {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.evento-imagem {
  // width: 100%;
  img {
    width: 100%;
  }
}

.imagem-evento--pequena-home {
  object-fit: cover;

  @media (min-width: 576px) {
    max-height: 105px;
  }
}

.gradiente-leitura {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, #000000 120%);
  top: 0;
  left: 0;
}

@supports (display: grid) {
  .filter-eventos-grid {
    list-style: none;
    display: grid;
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    // align-items: flex-end;
    grid-template-columns: repeat(5, auto);

    & > .filter-item {
      & > div > .filter-text {
        color: $laranja !important;
        font-weight: bold;
      }
    }

    @media (max-width: 450px) {
      align-content: space-around;
      justify-content: space-between;
      grid-template-columns: repeat(2, auto);
    }
  }
}
