.wrapper {
    filter: contrast(80);
    background: white;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .c-item {
    width: 100px;
    height: 100px;
    margin: 0 8px;
    background: #00ccff;
    border-radius: 50%;
    filter: blur(30px);
    transform: scale(1) translateX(0);
  
    animation-name: load;
    animation-duration: 1.1s;
    animation-iteration-count: infinite;
  }
  .c1 {
    animation-delay: 0.1s;
  }
  .c2 {
    animation-delay: 0.3s;
  }
  .c3 {
    animation-delay: 0.5s;
  }
  .c4 {
    animation-delay: 0.7s;
  }
  @keyframes load {
    0% {
      transform: scale(1) translateX(60px);
    }
    50% {
      transform: scale(1.2) translateX(-60px);
    }
    100% {
      transform: scale(1) translateX(60px);
    }
  }
  @media screen and (max-width: 992px) {
    .c-item {
      width: 50px;
      height: 50px;
      margin: 0 0px;
      filter: blur(10px);
    }
    @keyframes load {
      0% {
        transform: scale(1) translateX(30px);
      }
      50% {
        transform: scale(1.2) translateX(-30px);
      }
      100% {
        transform: scale(1) translateX(30px);
      }
    }
  }