@import url("../fonts/stylesheet.css");
@import "../styles/colors.scss";
@import "../styles/fonts.scss";
@import url("https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css");

body {
  font-family: 'Quicksand';
  line-height: 1.3;
}

#body {
  background: #efefef !important;
  background-color: #efefef !important;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-button {
  height: 3px !important;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  background-color: $cinza-escuro;
}

button {
  box-shadow: unset !important;
  outline: unset !important;
}

:disabled {
  background: #ccc;
  cursor: not-allowed;
}

.w-45 {
  width: 45%;
}

.btnBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 3rem;
}


.btn,
.Toastify__toast-body,
.modal-body,
.fontQuick {

  font-family: 'Quicksand';

}


// .apexcharts-xcrosshairs,
// .apexcharts-active {

//   font-family: 'Quicksand';


// }