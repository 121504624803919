@import "../../styles/colors";

.navbar-primary {
  background-color: transparent;
  .navbar {
    .navbar-collapse {
      @media (max-width: 991px) {
        background: #ffffff;
        z-index: 1;
        padding: 30px;
        border-bottom-left-radius: 16px;
        margin: 0 -30px;
      }
    }
  }
  a,
  .nav-link {
    color: #fff !important;
    font-size: 16px;
    font-weight: 300;
  }
  .navbar-light .navbar-toggler {
    border: none;
    font-size: initial;
    padding: 0;
  }
}

.navbar-secondary {
  background-color: #fff;
  box-shadow: 0px 16px 24px rgba(52, 113, 116, 0.1);
  display: flex;
  .container {
    @media (max-width: 991px) {
      max-width: 100%;
    }
  }
  .navbar {
    height: 96px;
    .navbar-collapse {
      @media (max-width: 991px) {
        background: #ffffff;
        z-index: 1;
        padding: 30px;
        border-bottom-left-radius: 16px;
        margin: 0 -30px;
      }
    }
  }
  a,
  .nav-link {
    color: #401f00 !important;
    font-size: 16px;
    font-weight: 500;
  }
  .navbar-light .navbar-toggler {
    border: none;
    font-size: initial;
    padding: 0;
  }
  .button-outline {
    color: #401f00;
    border: 1.5px solid #401f00 !important;
  }
}

.navbar-mobile {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 2;
  overflow: hidden;
  @media (min-width: 991px) {
    display: none;
  }
  .nav-item {
    padding: 10px 0;
  }
  a,
  .nav-link {
    color: #401f00 !important;
    font-size: 25px;
  }
  .button-outline {
    color: #401f00;
    border: 1.5px solid #401f00 !important;
  }
  button {
    width: 130px !important;
  }
}

.navbar-toggler {
  z-index: 3;
}
.navbar-toggler.close {
  position: fixed;
  right: 15px;
}
