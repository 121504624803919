@import url("../../fonts/stylesheet.css");
@import "../../styles/colors";


abbr[data-original-title],
abbr[title] {
    text-decoration: none;
}

.react-calendar {
    width: 100% !important;
    background: white;
    border: 0px !important;
    font-family: "Quicksand";
    line-height: 1.125em;
    border-radius: 20px;
}

.react-calendar__tile--active{
    background: $laranja;
}

.default-calendario {
    .react-calendar__navigation button[disabled] {
        background-color: $laranja;
    }
    .react-calendar__navigation {
        background-color: $laranja;
    }
    
    .react-calendar__month-view__days__day--neighboringMonth {
        color: #e8e8e8;
        background-color: #e8e8e8;
    }
    .react-calendar__tile--now:enabled:hover,
    .react-calendar__tile--now:enabled:focus {
        background: $laranja;
    }
    .react-calendar__tile--hasActive {
        background: $laranja;
    }
    .react-calendar__tile--hasActive:enabled:hover,
    .react-calendar__tile--hasActive:enabled:focus {
        background: $laranja;
    }
    .react-calendar__tile--active {
        background: $laranja;
        color: white;
    }

    .react-calendar__tile--active:enabled:hover,
    .react-calendar__tile--active:enabled:focus {
        background: $laranja;
    }
    .react-calendar__navigation__label__labelText {
        color: white;
        font-family: Quicksand;
        
    }
}

.with-calendar {
    &:disabled {
        background: $laranja;
        color: $laranja;
    }
    .react-calendar__navigation button[disabled] {
        color: $laranja;
        background-color: $laranja;
    }

    .react-calendar__navigation {
        background-color: white;
    }

    .react-calendar__navigation__label__labelText {
        color: black;
        font-family: Quicksand;
        background: $laranja;
    }

    .react-calendar__navigation__next-button,
    .react-calendar__navigation__prev-button {
        color: #fff;
        font-size: 3rem;
        background: $laranja;
    }

    .react-calendar__month-view__days__day--neighboringMonth {
        color: #e8e8e8;
        background-color: #e8e8e8 !important;
    }
    .react-calendar__tile--now:enabled:hover,
    .react-calendar__tile--now:enabled:focus {
        background: $laranja;
    }
    .react-calendar__tile--hasActive {
        background: $laranja;
    }
    .react-calendar__tile--hasActive:enabled:hover,
    .react-calendar__tile--hasActive:enabled:focus {
        background: $laranja;
    }
    .react-calendar__tile--active {
        background: $laranja;
        color: #fff;
    }

    .react-calendar__tile--active:enabled:hover,
    .react-calendar__tile--active:enabled:focus {
        background: $laranja;
    }
}

//Styles cabeçalho
.react-calendar__navigation__label__labelText {
    font-size: 1rem;
    text-transform: uppercase;
    color: #fff;
}
.react-calendar__navigation {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    zoom: 1;
    filter: alpha(opacity=70);
    opacity: 0.7;
    height: 68.5714px;
    background: $laranja;
}
.react-calendar__navigation button {
    min-width: 44px;
    background: $laranja;
    font-size: 30px;
    color: #fff;
    border-radius: 20px;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus{
    background: $laranja;
    color: #e8e8e8;
}
//botões de navegação
.react-calendar__navigation__prev-button:hover,
.react-calendar__navigation__prev-button:focus,
.react-calendar__navigation__prev2-button:hover,
.react-calendar__navigation__prev2-button:focus,
.react-calendar__navigation__next-button:hover,
.react-calendar__navigation__next-button:focus,
.react-calendar__navigation__next2-button:hover,
.react-calendar__navigation__next2-button:focus{
    background: $laranja !important;
    color: #fff;
}

//botões de navegação desabilitados
.react-calendar__navigation__next-button[disabled],
.react-calendar__navigation__prev-button[disabled]{
    background: $laranja !important;
    color: $laranja !important;    
}

.react-calendar__navigation__label {
    background: $laranja !important;
    color: #fff;
}


//Dias em aberto
.react-calendar__tile {
    max-width: 100%;
    text-align: center;
    padding: 0.75em 0.5em;
    background: #fff;
}

//dia de hoje
.react-calendar__tile--now {
    text-decoration: underline;
}

//Dias da semana(seg, ter, qua,...)
.react-calendar__month-view__weekdays__weekday {
    text-overflow: ellipsis;
    overflow: hidden;
    background: #fff;

}

//Style dos botões de dias e mês
.react-calendar__month-view__days__day--neighboringMonth {
    color: #e8e8e8;
    background-color: #e8e8e8 !important;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    background: $laranja;
}
.react-calendar__tile--hasActive {  //V
    background: $laranja;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
    background: $laranja;
}
.react-calendar__tile--active { //V
    background: $laranja;
    color: #fff;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: $laranja;
}