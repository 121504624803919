@import "../../styles/colors";

// ---------------------- progress-bar background -----------------
.Toastify__progress-bar--default {
    background: linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55);
}
.Toastify__progress-bar--dark {
    background: #bb86fc;
}
//-------------------------------------------------------------------

// ---------------- cor do toast ----------------------
.Toastify__toast--dark {
    background: #121212;
    color: #fff;
}
.Toastify__toast--default {
    background: #fff;
    color: #aaa;
}
.Toastify__toast--info {
    background: $azul; // MUDADO
}
.Toastify__toast--success {
    background: #07bc0c;
}
.Toastify__toast--warning {
    background: $amarelo; // MUDADO
}
.Toastify__toast--error {
    background: #eb5757; // MUDADO
}
// --------------------------------------
