@import "../../styles/colors";

.eventos {
  background-color: #fff;
  width: 100%;
  padding-bottom: 300px;
  position: relative;

  .endereco-wave {
    position: absolute;
    width: 100%;
    // height: 200px;
    bottom: 0;
    margin-bottom: -10px;
    left: 0;
  }

  .evento:nth-child(2) {
    @media (max-width: 768px) {
      flex-direction: column-reverse;
    }
  }
  .titles {
    h3 {
      font-size: 32px;
    }
    span {
      font-size: 22px;
      color: #401f00;
    }
    p {
      font-size: 20px;
      color: #847770;
    }
    button {
      height: 55px !important;
    }
    data {
      font-size: 18px;
    }
  }
  &-img {
    background: cover;
    width: 946;
    height: 556px;
    @media (max-width: 768px) {
      margin-top: 20px;
    }
  }

  .social-midias {
    a {
      img {
        width: 70% !important;
      }
    }
  }
  &-info {
    h4 {
      color: #401f00;
      font-size: 38px;
    }
    span {
      color: #401f00;
      font-size: 20px;
    }
  }
}
