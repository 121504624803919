@import url("../../fonts/stylesheet.css");

.txt1 {
    //font-family: "AmpleSoft_Pro_Bold";
    // top: 119px;
    // left: 740px;
    width: "auto";
    // height: 166px;
    // transform: matrix(1, -0.09, 0.09, 1, 0, 0);
    // text-align: left;
    // letter-spacing: 0px;
    //color: #2cccd3;
    // opacity: 1;
    font-size: 40px;
    @media screen and (max-width: 700px) {
        font-size: 30px;
    }
}

.txt2 {
    //font-family: "AmpleSoft_Pro_Bold";
    color: #666666;
    font-size: 24px;
    line-height: 1.2;
}

.txt-alert {
    color: darkred;
    font-size: 19px;
    line-height: 1;
}

.circle {
    background: #2cccd3 0% 0% no-repeat padding-box;
    border: 7px solid #2cccd3;
    width: 24px;
    height: 24px;
    border-radius: 50px;
    margin-right: 10px;
}

.placeholder {
    display: flex;
    pointer-events: none;
    font-size: 1.25rem;
    font: normal normal bold 20px/25px Quicksand;
}

.placeholder-bold {
    font-family: "Quicksand";
}

.explanation {
    font-family: "Quicksand";
    font-size: 18px;
}

.with {
    color: #ffffff;
}

.black {
    color: #000000;
}
