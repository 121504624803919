@import "../../styles/colors";

.default {
  background: $laranja;
  color: #fff;
}

.disabled {
  background: #ccc !important;
}

.secondary {
  background: #ffffff;//Estes 2
  color: $laranja;//eram os únicos no secondary
}

.button-branco {
  background: #ffffff;
  color: #0883ee;
  // border: ;
}

.button-laranja {
  background-color: $laranja;
  color: #fff;
  font-size: 16px !important;
  font-weight: 500 !important;
  height: 36px !important;
  border-radius: 36.5px !important;
  width: auto !important;
  padding: 0 24px;
}

.button-vermais {
  background-color: $laranja;
  color: #fff;
  font-size: 18px !important;
  font-weight: 500 !important;
  height: 36px !important;
  border-radius: 36.5px !important;
  width: auto !important;
  padding: 0 24px;
}

.button-outline {
  background-color: transparent;
  color: #fff;
  border: 1.5px solid #FFFFFF !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  height: 36px !important;
  border-radius: 36.5px !important;
  width: auto !important;
  padding: 0 24px;
}

.btn-custom {
  width: 150px;
  border: 1.5px solid $laranja;
  //outline: none;
  height: 49px;
  border-radius: 49px;
  font-weight: 600;
  margin: 10px 0;
  cursor: pointer;
  transition: 0.5s;
  border-radius: 35px !important;
  font-family: Quicksand;
  
}

// .btn-custom:hover {
//   // background-color: #2cccd2;
// }

.btn-custom:focus {
  outline: 0;
}

.btn-acessar{
  width: 150px !important;
  border: none;
  height: 49px !important;
  border-radius: 49px;
  font-weight: 600;
  margin: 10px 0;
  cursor: pointer;
  transition: 0.5s;
  border-radius: 35px;
  font-family: 'Quicksand';
  color: #ffffff;
}

.voltarAgendamento{
  color: #666666;
}
