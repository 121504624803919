@import "../../styles/colors";

.slick-slide {
  padding: 0 10px !important;
}

.slick-next:before,
.slick-prev:before {
  display: none !important;
}
.slick-prev {
  left: -50px !important;
  @media (max-width: 768px) {
    display: none !important;
  }
}
.slick-next {
  transform: rotateY(180deg) !important;
  right: -50px !important;
  @media (max-width: 768px) {
    display: none !important;
  }
}
.slick-dots li.slick-active svg {
  background: $laranja !important;
  border-radius: 50% !important;
}
