@import "../../styles/colors";

.footer-home {
  // background: url(/images/beach_footer.svg);
  // background-repeat: no-repeat;
  // background-size: cover;
  background-color: #FFEDAD;
  // bottom: 0;
  position: relative !important;
  width: 100%;
  // height: 100%;
  // margin-top: -110px;

  .footer-estrela{
    position: absolute;
    left: 48%;
    top: 0;
    margin-top: -20px;

  }

  // .egg {
  //   display: flex;
  //   height: 50px;
  //   width: 300px;
  //   align-items: center;
  //   justify-content: center;

  //   @media (max-width: 991px){
  //     display: none;
  //   }
  // }

  .footer-bola{
    position: absolute;
    left: 60%;
    bottom: 20%;

    @media (max-width: 991px){
      display: none;
    }
  }

  .footer-tapete{
    position: absolute;
    left: 80%;
    bottom: 50%;

    @media (max-width: 991px){
      display: none;
    }
  }
  .logo {
    @media (max-width: 768px) {
      text-align: center;
    }
  }
  .row {
    padding: 45px 0 45px 0;
  }
  button {
    height: 35px !important;
  }
  span {
    font-size: 17px;
  }
  .social-midias {
    display: flex;
    align-items: center;
    align-self: center;
    width: 100%;
    a + a{
      margin-left: 8px;
    }
  }
  p {
    font-size: 14px;
  }
  nav {
    h4 {
      color: #401f00;
      font-size: 20px;
    }
    ul {
      list-style-type: none;
      padding: 0;
      font-size: 16px;
      li {
        padding: 3px 0;
      }
      li a {
        color: #401f00;
        text-decoration: none;
      }
    }
  }
}
